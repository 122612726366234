import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// import QuestionnaireDetails from '@/views/questionnaireDetails'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
    name: "home"
    // title:"门店查询",
    // component: () => import('@/views/preselection/index')
  },
  {
    path: '/index',
    name: 'index',
    title: "首页",
    component: () => import('@/views/index/index')
  },
  {
    path: '/login',
    name: 'login',
    title: "登录",
    component: () => import('@/views/login/index')
  },
  {
    path: '/ranging',
    name: 'ranging',
    title: "选址测距",
    component: () => import('@/views/ranging/index')
  },
  {
    path: '/preselection',
    name: 'preselection',
    title: "选址测距",
    component: () => import('@/views/preselection/index')
  },
  {
    path: '/user',
    name: 'user',
    title: "选址测距",
    component: () => import('@/views/user/index')
  },
  {
    path: '/addressForm',
    name: 'addressForm',
    title: "预选地址",
    component: () => import('@/views/addressForm/index')
  },
]

const errorPage = [
  {
    path: '/*',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('@/views/error/404')
  }
]
const router = new VueRouter({
  mode: 'history',
  routes: [...routes, ...errorPage]
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(async (to, from, next) => {
  // 判断是否需要登录才可以进入
  if (!storage.get(ACCESS_TOKEN) && !storage.get('USERINFO') && to.path !== '/login') {
    return next('/login')
  }
  console.log(to, from);
  if (to.name === 'addressForm' && from.name === 'user') {
    return next('/preselection')
  }
  next()
})
export default router
