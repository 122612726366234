import axios from 'axios'
import { Notify } from 'vant'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
import qs from 'qs'
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_FT_URL,
  timeout: 20000, // request timeout
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;',
    'X-Requested-With': 'XMLHttpRequest'
  }
})
// 异常拦截处理器
const errorHandler = error => {
  console.log(error);
  
  const token = storage.get(ACCESS_TOKEN)
  if (error.response) {
    const data = error.response.data
    Notify({ type: 'warning', message:  data.message || '接口报错' })
  }

  if (token) {
    store.dispatch('Logout').then(() => {
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    })
  }
  return Promise.reject(error)
}

// 请求拦截器
service.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token
  if (token) {
    config.headers['Authorization'] = token
  }
  if (config.method === 'post') {
    config.data = qs.stringify(config.data)
  }
  return config
}, errorHandler)


service.interceptors.response.use(response => {
  console.log(response);
  var data = response.data
  if(data.data&&data.data.code&&!data.isVoid){
    return Promise.reject(response.data)
  }
  if (!data.status) {
    Notify({ type: 'warning', message:  data.message || '接口报错' })
    return Promise.reject(response)
  }
  return response.data
}, errorHandler)



export default service



