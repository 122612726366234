import storage from 'store'
import { login,logout } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const user = {
    state: {
        token: '',
        userInfo:{}
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_INFO:(state, userInfo) => {
            state.userInfo = userInfo
        },
    },

    actions: {
        // 登录
        Login({ commit }, token) {
            return new Promise((resolve, reject) => {
                storage.remove(ACCESS_TOKEN)
                login(token)
                    .then(response => {
                        console.log(response);
                        const result = response.data
                        storage.set(ACCESS_TOKEN, result.jwt, 1 * 24 * 60 * 60 * 1000) //一天过期
                        storage.set('USERINFO', result, 1 * 24 * 60 * 60 * 1000) //一天过期
                        commit('SET_TOKEN', result.jwt)
                        commit('SET_INFO', result)
                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        // 登出
        Logout() {
            return new Promise((resolve, reject) => {
                storage.remove(ACCESS_TOKEN)
                storage.remove('USERINFO')
                resolve()
            });
        },

    }
}

export default user
