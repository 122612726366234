import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/vant.js'
import './style/components.css'
import tabbarBox from '@/components/tabbar'
// import VConsole from 'vconsole'
Vue.component('tabbar-box', tabbarBox)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
