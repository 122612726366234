import service from "@/utils/ftRequest"

//验证码
export function captcha(parameter) {
    return service({
        url: "/uc/captcha",
        method: 'post',
        data: parameter,
        isUC:true
    })
}
//登录
export function login(parameter) {
    return service({
        url: "/uc/login",
        method: 'post',
        data: parameter,
        isUC:true

    })
}
//登录
export function logout(parameter) {
    return service({
        url: "/uc/logout",
        method: 'get',
        params: parameter,
        isUC:true
    })
}