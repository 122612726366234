import Vue from 'vue'
import {
  Button,
  Form,
  Field,
  Dialog,
  Picker,
  Popup,
  Icon,
  Toast,
  Tabbar,
  TabbarItem,
  Cascader,
  Search,
  RadioGroup,
  Radio,
  Loading,
  Notify,
  Slider,
  PullRefresh
} from 'vant'
Vue.use(Button)
Vue.use(Field)
Vue.use(Form)
Vue.use(Picker)
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Cascader);
Vue.use(Search);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Slider);
Vue.use(PullRefresh);
