// JAVA api
import service from '@/utils/request'


//逆解析
export function getDistrict(parameter) {
    return service({
        url: "/h5/map/district",
        method: 'get',
        params: parameter
    })
}
//城市信息
export function getCity(parameter) {
    return service({
        url: "/common/district/three-tree",
        method: 'get',
        params: parameter
    })
}
//店铺位置信息
export function getStoreList(parameter) {
    return service({
        url: "/admin/address/city-place",
        method: 'get',
        params: parameter
    })
}
//地址是否可选
export function addressVerify(parameter) {
    return service({
        url: "/h5/address/verify",
        method: 'get',
        params: parameter
    })
}
//合同列表
export function getContractList(parameter) {
    return service({
        url: "/h5/contract/list",
        method: 'get',
        params: parameter
    })
}
//合同信息
export function getContractDetails(parameter) {
    return service({
        url: "/h5/contract/details",
        method: 'get',
        params: parameter
    })
}
//合同信息
export function getNearbyShop(parameter) {
    return service({
        url: "/h5/contract/shop",
        method: 'get',
        params: parameter
    })
}
//选址地图概况-区域保护
export function protectedArea(parameter) {
    return service({
        url: '/admin/address/protected-area',
        method: 'get',
        params: parameter
    })
}
//选址地图概况-预占创建
export function createAddress(parameter) {
    return service({
        url: '/h5/preempt/create',
        method: 'post',
        data: parameter
    })
}
//个人中心预占列表
export function recordList(parameter) {
    return service({
        url: '/h5/preempt/list',
        method: 'get',
        params: parameter
    })
}