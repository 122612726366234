import { getContractDetails } from "@/api/api"
const user = {
    state: {
        contractInfo:{}, //预选合同信息
    },

    mutations: {
        setContract(state,value){
            state.contractInfo = value
        }
    },

    actions: {
        setContract({commit},value){
            console.log(value);
            return new Promise((resolve)=>{
                getContractDetails({
                    contract_number:value
                }).then(res=>{
                    commit('setContract',res.data)
                    resolve()
                }).catch(err=>{
                    console.log(err);
                })
            })
        }
    }
}

export default user
