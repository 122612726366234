import axios from 'axios'
import { Notify } from 'vant'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
import store from '@/store'
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URLS,
  timeout: 20000, // request timeout
  headers: {
    'Content-Type': 'application/json;',
    // 'X-Requested-With': 'XMLHttpRequest'
  }
})
// 异常拦截处理器
const errorHandler = error => {
  // const token = storage.get(ACCESS_TOKEN)
  if (error.response) {
    const data = error.response.data
    Notify({ type: 'warning', message: data.message || data.msg || '接口报错' })
  }

  // if (token) {
  //   store.dispatch('Logout').then(() => {
  //     setTimeout(() => {
  //       window.location.reload()
  //     }, 1500)
  //   })
  // }
  return Promise.reject(error)
}

// 请求拦截器
service.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // console.log(config);
  if (config.config?.isUC) {
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
  }
  // 如果 token 存在
  // 让每个请求携带自定义 token
  if (token) {
    config.headers['Access-Token'] = token
  }
  // if (config.method === 'post') {
  //   config.data = qs.stringify(config.data)
  // }
  return config
}, errorHandler)


service.interceptors.response.use(response => {
  var data = response.data
  if (response.status != 200) {
    Notify({ type: 'warning', message: data.message || '接口报错' })
    return Promise.reject(response.data)
  }
  if (response.status == 200 && data.code != 0) {
    Notify({ type: 'warning', message: data.msg || '接口报错' })
    if (data.code == 410002) {
      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
    }
    return Promise.reject(response)
  }
  return response.data
}, errorHandler)



export default service



