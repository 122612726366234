<template>
    <div class="cus_tabbar">
        <van-tabbar v-model="index"  active-color="#1A1D20">
            <van-tabbar-item v-for="(item,index) in list" :key="index" @click="$router.replace({
                path:item.url
            })">
                <template #icon="props">
                    <img :src="props.active ? item.selectIcon : item.noselectIcon" />
                </template>
                {{item.title}}</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>

export default {
    props:{
        active:{
            default:0
        }
    },
    name: '',
    data() {
        return {
            index:0,
            list:[
                {title:"门店查询",selectIcon:require("@/assets/select_index.png"),noselectIcon:require("@/assets/noselect_index.png"),url:"/index"},
                {title:"选址测距",selectIcon:require("@/assets/select_ranging.png"),noselectIcon:require("@/assets/noselect_ranging.png"),url:"/ranging"},
                {title:"预选地址",selectIcon:require("@/assets/select_beforehand.png"),noselectIcon:require("@/assets/noselect_beforehand.png"),url:"/preselection"},
                {title:"个人中心",selectIcon:require("@/assets/select_user.png"),noselectIcon:require("@/assets/noselect_user.png"),url:"/user"},
            ]
        }
    },
    created(){
        this.index= this.active
    },
    methods: {
    },
}

</script>
<style scoped>
.cus_tabbar {
    position: fixed;
    bottom: 0;
    left: 0;
    /* background-color: #fff; */
    height: 100px;
    width: 100%;
    z-index: 999;
}
</style>