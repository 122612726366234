import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import contract from './modules/contract'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    contract
  },
  state: {},
  mutations: {},
  actions: {
    
  }
})
